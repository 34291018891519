import React, {Component} from 'react';
import {Image as Images} from "../../Images";
import {Button, Icon, Spin} from "antd";
import AddItemsListDrawer from "../../drawers/work-order/add-items/AddItemsListDrawer";
import {locationItemGet, locationItemRemove} from "../../../controller/API/salesOperationAPI";
import {withTranslation} from "react-i18next";
import SingleItemListCard from "./SingleItemListCard";
import { quantity_update } from '../../../controller/API/salesOperationAPI';
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../redux/Actions/WarehouseAction";
import {isAccessible} from "../../../utils";

class ItemList extends Component {
    state = {
        itemListShow: false,
        radioValue: 1,
        items: [],
        params: {location_id: this.props.location.id},
        loading: true,
    };

    itemListVisible = (visible) => {
        const {params} = this.state;
        this.setState({
            itemListShow: visible
        });
        if (!visible) {
            this.fetch(params);
            this.props.fetch()
        }
    };

    fetch = (params = {}) => {
        locationItemGet(params)
            .then(response => {
                if (response.count > 0) {
                    this.props.buttonFaded();
                }
                this.setState({items: response.data, loading: false})
                if(response.data.length <= 0){
                    this.itemListVisible(true);
                }
            })
    };


    deleteItem = (id) => {
        locationItemRemove(id)
            .then(() => this.fetch(this.state.params))
    };

    componentDidMount() {
        const {params} = this.state;
        this.fetch(params);
    }


    updateItem = (id, data) => {
        quantity_update({'work_order_item_id':id,'quantity': data['quantity']}).then(() => this.fetch(this.state.params))

    };

    getItemsList() {
        const {items, params} = this.state;
        const warehouseMapping = {}
        for (let item of items) {
            if (!(item.warehouse_item.warehouse in warehouseMapping)) {
                warehouseMapping[item.warehouse_item.warehouse] = []
            }
            warehouseMapping[item.warehouse_item.warehouse].push(item)
        }

        let nodes = []
        for (const warehouse in warehouseMapping) {
            if (isAccessible(['admin'])){
                nodes.push(
                    <p className={`mt-4 text-left font-weight-bold`} style={{ color: '#448de5' }} key={`warehouse_${warehouse}`}>
                        {this.props.warehouse_id_mapping[warehouse].name} <br />
                        <span className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].address}</span><br />
                        <span className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].business_hours}</span><br />
                        <span className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].phone}</span>
                    </p>
                )
            }

            warehouseMapping[warehouse].map((data, index) => nodes.push(
                <SingleItemListCard key={`item_card_${index}`} data={data} update={(id, data) => this.updateItem(id, data)}
                                    fetch={() => this.fetch(params)}
                                    remove={(id) => this.deleteItem(id)}
                                    workorder={this.props.workorder}/>
            ))

        }
        return nodes
    }

    render() {
        const {itemListShow, items, loading} = this.state;
        const {location, t, workorder} = this.props;
        if (loading) {
            return <div className={'mt-5 p-0 text-center'}><Spin/></div>
        }
        const warehouse_set = new Set(items.map(item => item.warehouse_item.warehouse));
        const warehouse_ids = Array.from(warehouse_set.values())
        const warehouse_names = warehouse_ids.map(id => this.props.warehouse_id_mapping[id].name)

        return (
            <React.Fragment>
                {items.length > 0 ?
                    <React.Fragment>
                        <div className="add-more-btn">
                            <Button onClick={() => this.itemListVisible(true)}
                                    icon="plus">{t('add_article_caps')}</Button>
                        </div>
                        {this.getItemsList()}
                    </React.Fragment>
                    :
                    !itemListShow && <div className="card-add-items-div position-relative card-before h-100 flex-align-center-center">
                        <div className="text-center">
                            <img src={Images.add_article_gray} alt="delivery icon" className="img-fluid mb-3"/>
                            <p>{t('items_add')}</p>
                            <Button type="primary" onClick={() => this.itemListVisible(true)}>
                                <Icon type="plus"/>
                                {t('add_article_caps')}
                            </Button>
                        </div>
                    </div>
                }
                <AddItemsListDrawer totalItems={items.length} current_location={location} visible={itemListShow}
                                    onClose={() => this.itemListVisible(false)} warehouseID={this.props.warehouseID}
                                    workorder={workorder}/>
            </React.Fragment>
        );
    }
}

export default (withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(ItemList)));
