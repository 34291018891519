import React, {Component} from 'react';
import {Image as Images} from "../Images";
import {withTranslation} from "react-i18next";
import {Button} from "antd";
import { UpdateAddress } from '../modal/work-order/UpdateAddress';

class LocationCard extends Component {

    state={
        visible: false,
    }

    handleAddressModal =(visible)=>{
        this.setState({visible})
    }

    render() {
        const {visible} = this.state;
        const {data, buttonVisible, workorder} = this.props;

        return (
            <div className="text-center w-100 card-shopping">
                <img src={Images.ware_house_big} alt="delivery icon"
                     className="img-fluid"/>
                <h5>{data.address_json.type}</h5>
                <p>{data.address_json.title}</p>
                <p className="mb-0">{`${data.address_json.address}, ${data.address_json.zip_code}, ${data.address_json.city}, ${data.address_json.country}`}</p>
                {buttonVisible && <Button style={{backgroundColor: 'transparent',
                    borderRadius: '60px',
                    borderColor: '#2196F380',
                    color: '#2196F3', 
                    padding: '0 15px', 
                    marginTop: '10px'}}
                    onClick={()=> this.handleAddressModal(true)}> MODIFIER
                    </Button>}
                <UpdateAddress visible={visible} onClose={() => this.handleAddressModal(false)} workorder={workorder} oldAddress={data} fetch={this.props.fetch}/>

            </div>
        )
    }
}

export default (withTranslation('common')(LocationCard));
