import React, {Component} from 'react';
import {Button, Drawer, Input, message, Select, Spin} from 'antd';
import {Image as Images} from "../../../Images";
import {itemCategoryGet, productGet, warehouseGet} from "../../../../controller/API/itemApi";
import {SingleItemCard} from "./singleItemCard";
import {withTranslation} from "react-i18next";
import {distributorWorkorderItemAdd, workorder_component} from "../../../../controller/API/salesOperationAPI";
import AddComponentListDrawerStep3 from './AddComponentListDrawerStep3';
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../../redux/Actions/WarehouseAction";
import { isAccessible } from '../../../../utils';

const {Search} = Input;

const {Option} = Select;

class AddItemsListDrawer extends Component {

    state = {
        items: [],
        category: [],
        params: {category_id: null, warehouse: null, },
        fetched: false,
        loading: true,
        totalItems: this.props.totalItems,
        page: 1,
        is_load_more: true,
        updateItems: false,
        itemListShow:false,
        data:null,
        ComponentData:[]
    };

    componentDidMount() {
        this.fetchCategory();
        this.fetch();
        if (this.props.warehouseID !== undefined) {
            this.handleWarehouseFilter(this.props.warehouseID)
        }
        else {
            this.handleWarehouseFilter(this.props.warehouses[0].id)
        }
    }


    handleSubmit = (product, values, selectedVariantId) => {
        values['workorder_address'] = this.props.current_location.id;
        let variant = product.variant.find(variant => variant.id === selectedVariantId)
        values['warehouse_item'] = variant.warehouse_inventory_object.find(warehouse_inventory => warehouse_inventory.warehouse === this.state.params.warehouse).id;
        distributorWorkorderItemAdd(values)
            .then((res) => {
                this.setState({data:res.data})
                this.fetchComponent(res.data.id);
                message.success("Produit(s) ajouté(s) à votre panier.");
                this.setState({totalItems: this.state.totalItems + 1})
            }).catch(e => {
            message.error("some error occurred");
        });
    };

    fetchCategory = (params = {}) => {
        itemCategoryGet(params)
            .then(category => {
                this.setState({category})
            })
    };

    handleFilter = (value) => {
        let {params} = this.state;
        params["category_id"] = value;
        this.setState({params});
        this.fetch(params);
    };

    handleWarehouseFilter = (value) => {
        let {params} = this.state;
        params["warehouse"] = value;
        this.setState({params});
        this.fetch(params);
    }

    loadMoreItem = () => {
        this.setState({loadMoreLoading: true, updateItems: true})
        const page = this.state.page + 1
        let new_params = {...this.state.params, page};
        productGet(new_params)
            .then(response => {
                const items = this.state.items
                this.setState({
                    items: [...items, ...response.data.results],
                    fetched: true,
                    loading: false,
                    loadMoreLoading: false,
                    updateItems: false,
                    page: new_params.page || 1,
                    is_load_more: Boolean(response.data.next)
                })
            })
    }

    fetch = (params = {}) => {
        this.setState({updateItems: true})
        let new_params = {...this.state.params, ...params};
        productGet(new_params)
            .then(response => {
                this.setState({
                    items: response.data.results,
                    updateItems: false,
                    fetched: true,
                    loading: false,
                    is_load_more: Boolean(response.data.next)
                })
            })
    };
    fetchComponent = (id) => {
        workorder_component({ workorder_item: id }).then(response => {
            if(response.message){
              message.error(response.message)
              this.setState({
                ComponentData: [],
                itemListShow:true
            })
            }else{
    
              this.setState({
                  ComponentData: response.result,
                  itemListShow:true
              })
            }
        })
      };

    itemListVisible = (visible) => {
        const { params } = this.state;
        this.setState({
            itemListShow: visible,
        });
    };
    render() {
        const {current_location, t, workorder} = this.props;
        const {category, buttonLoading, totalItems, loadMoreLoading, is_load_more, updateItems,itemListShow,data,ComponentData} = this.state;
        const items = this.state.items.filter(o => o.variant.length > 0);
        if (this.state.loading && workorder.client) {
            return <div className={'mt-5 p-0 text-center'}><Spin/></div>
        }
        return (
            <Drawer
                title={t('add_article')}
                placement="right"
                closable={false}
                onClose={this.props.onClose}
                visible={this.props.visible}
                width="81.3%"
            >
                <div className="row mx-0">
                    <Button onClick={this.props.onClose}
                            className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0">
                        <img src={Images.close_icon} alt="close icon" className="img-fluid"/>
                        <div>{t('close_btntext')}</div>
                    </Button>
                    <div className="col-12 clearfix list-main-drawer">
                        <div className="row mx-0 add-item-list-row-header">
                            <div className="col-12 p-0">
                                <div className="row mx-0 list-item-second-header pt-0 align-items-center w-100">
                                    <div className="col-sm-12 col-lg-4 col-md-12 col-12">
                                        <h6 className="m-0 font-weight-bold">
                                            <div className="img-cart-add-tag position-relative float-left mr-1">
                                                <img src={Images.warehouse_black_icon} alt="ware-house-icon"
                                                     className="img-fluid mr-1"/>
                                                {/*to show cart is not empty*/}
                                                <span className="position-absolute tag-cart-notification"> </span>
                                            </div>
                                            {current_location.address_json.type} :
                                            <small> {totalItems} Article(s) ajouté(s)</small>
                                        </h6>
                                    </div>
                                    <div className="col-12 col-sm-12 col-lg-8 col-md-12 pr-0">
                                        <div className="row mx-0">
                                        {isAccessible(["admin"]) &&
                                            <Select defaultValue={this.props.warehouseID || this.props.warehouses[0].id} style={{ width: '20%'}} className={'mr-3'} onChange={this.handleWarehouseFilter}>
                                                {
                                                    this.props.warehouses
                                                        .map((warehouse, index) =>
                                                        <Option key={`warehouse_${index}`} value={warehouse.id}>{warehouse.name}</Option>
                                                    )
                                                }
                                            </Select>}
                                            <Search
                                                placeholder="Rechercher des articles"
                                                onSearch={value => this.fetch({"search": value})}
                                                style={{width: isAccessible(["distributor"]) ? '70%' : '47%'}}
                                            />
                                            <Select placeholder="FILTRE
                                                    PRODUITS" className="select-div-item" onChange={this.handleFilter}>
                                                <Option key={`category_00`} value={null}>{t('all')}</Option>
                                                {category.map((d, index) =>
                                                    <Option key={`category_${index}`} value={d.id}>{d.name}</Option>
                                                )}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-0 list-item-card-row">
                            {items.length > 0 && !updateItems && items.map((d, index) =>
                                <SingleItemCard key={`product_card_${index}`} location={current_location} product={d}
                                                buttonLoading={buttonLoading}
                                                warehouse={this.state.params.warehouse}
                                                submit={(values, selected) => this.handleSubmit(d, values, selected)}
                                                variant={true}/>
                            )}
                        </div>
                        {is_load_more &&
                            <div className="text-center">
                                <Button loading={loadMoreLoading} className="load_more_btn" type="primary"
                                        onClick={this.loadMoreItem}>{t('load_more')}</Button>
                            </div>}
                        {/*<AddItemListView/>*/}
                        {ComponentData?.length > 0 && itemListShow && <AddComponentListDrawerStep3 warehouseID={this.state.params?.warehouse} data={data} ComponentData={ComponentData} visible={itemListShow} onClose={() => this.itemListVisible(false)}/>}
                    </div>
                    <div className="drawer-footer w-100 position-absolute text-right bg-white px-3 py-2 border-top">
                        <Button onClick={this.props.onClose} type="primary">
                            VALIDER
                        </Button>
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default (withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(AddItemsListDrawer)));
