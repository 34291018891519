import React, {Component} from 'react';
import {Button, Form, message, Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {distributorWorkorderItemBulkOrderTypeChange, locationItemUpdate} from "../../../../controller/API/salesOperationAPI";
import {order_type} from "../../../../controller/Global";
import {withTranslation} from "react-i18next";
import {Image as Images} from "../../../Images";
import ConfirmPopup from '../../../modal/ConfirmPopup';

class CustomerPurchaseModal extends Component {
    state = {
        bulkUpdateVisible: false,
        order_type: {},
        itemId: null
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values['order_type'] = order_type.customer_purchase;
                this.setState({order_type: values, bulkUpdateVisible: true, itemId: this.props.selected_item?.id})
                this.props.close()
            }
        })
    };
    
    updateSingleItem=()=> {
        locationItemUpdate(this.state.itemId, this.state.order_type)
            .then(() => this.props.fetch())
        this.setState({bulkUpdateVisible: false})

    };

    updateBulkOrderType=()=>{
        const data = {workorder: this.props.workorder?.id, ...this.state.order_type} 

        distributorWorkorderItemBulkOrderTypeChange(data)
        .then(()=> {
            this.props.fetch();
            message.success(" Changes successfully")
        })
        .catch(err=> {
            message.error("Something is not good.")
        })
        this.setState({bulkUpdateVisible: false})

    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const data = this.props.selected_item;
        const {t} = this.props;
        const {bulkUpdateVisible} = this.state;
        return (
            <div>
                <Modal
                    visible={this.props.visible}
                    onCancel={this.props.close}
                    width="45%"
                    title={
                        <div className="custom-header-modal">
                            <h4>{t('customer_purchase_title_show')}</h4>
                            <Button onClick={this.props.close}
                                    className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0">
                                <img src={Images.close_icon} alt="close icon" className="img-fluid"/>
                                <div>{t("close_window_new")}</div>
                            </Button>
                        </div>
                    }
                    className="hide-close-btn"
                    footer={[<Button key="submit" type="primary" onClick={this.handleSubmit}>
                        {t('valid')}
                    </Button>]
                    }>
                    {this.props.visible && <div className="p-4 row mx-0">
                        <Form.Item label={t('customer_purchase_input_fiel_label')}>
                            {getFieldDecorator('customer_note', {
                                initialValue: data.customer_note,
                                rules: [{
                                    required: true,
                                    message: t('enter')
                                }],
                            })(
                                <TextArea rows={6}/>,
                            )}
                        </Form.Item>
                    </div>}
                </Modal>
                {bulkUpdateVisible ? <ConfirmPopup
                            onOk={this.updateSingleItem}
                            width="50%"
                            onCancel={this.updateBulkOrderType}
                            okText={t('no')}
                            cancelText={t("yes")}
                            title={"ALERTE!"}
                            description={"Appliquer cette action à tous ?"}
                        /> 
                        : ''}
            </div>
        );
    }
}

export const CustomerPurchaseModel = Form.create()(withTranslation('common')(CustomerPurchaseModal));
