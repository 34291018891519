import React, {Component} from 'react';
import {Form, Radio, Modal, message} from "antd";
import {withTranslation} from "react-i18next";
import { addressGet } from '../../../controller/API/profileApi';
import { distributorWorkorderAddressChangePost } from '../../../controller/API/salesOperationAPI';


class UpdateAddressForm extends Component {
    state={
        all_locations: [],
        selectedAddress: null,
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values['workorder'] = this.props.workorder?.id;
                values['old_address'] = this.props.oldAddress?.address;
                values['new_address'] = parseInt(values['new_address']);

                distributorWorkorderAddressChangePost(values)
                .then(res => {
                    message.success("changement d'adresse avec succès")
                }).catch(err => {
                    Object.keys(err.response.data).forEach(d => {
                    message.warning(`${err.response.data[d]}`)
                    })
                });

            }
        });

        this.props.fetch()
        this.props.onClose()


    };

    componentDidMount() {
        if (this.props.workorder){
            this.fetch({client_id: this.props.workorder.client.id, "page_size": "all"})
        }
    }

    fetch = (params = {}) => {
        addressGet(params)
            .then(response => {
                this.setState({all_locations: response})
            })
    };

    handleRadio = (e) => {
        this.setState({ selectedAddress: e.target.value })
    };


    render() {

        const {t, workorder, oldAddress} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {all_locations} = this.state;
        return (
            <Modal
                style={{top: '26%'}}
                title={"SELECTIONNER L’ADRESSE DE LIVRAISON"}
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.props.onClose}
                width="30%"
                okText={'CHOISIR LES ADRESSES SÉLECTIONNÉES'}
                className="confirm-modal-main"
                cancelText={t('cancel')}
                closable={false}
                
            >
                <div className="row mx-0 py-4 px-3">
                    <div className="inner-details-row-item w-100">
                        <div className="row" style={{margin: '20px 40px'}}>
                            <div className="bg-white">
                                <Form onSubmit={this.handleSubmit} className="main-form">
                                    <Form.Item label={t('address')} className="mb-0">
                                        {getFieldDecorator('new_address', {
                                            initialValue: oldAddress?.address,
                                            rules: [{
                                                required: true,
                                                message: `${t('please_input')} ${t('address')} !`,
                                            }],
                                        })(<div className="row mx-0">
                                            <ul className="list-inline w-100">
                                                <Radio.Group defaultValue={oldAddress?.address}
                                                    onChange={this.handleRadio}>
                                                    {all_locations.map((d, index) => {
                                                        return <li key={`address_${index}`}>
                                                                <Radio className="position-relative w-100"
                                                                        style={{marginBottom: '10px', fontWeight: '600'}}
                                                                    value={d.id}>{d.type}<br /><span
                                                                        style={{
                                                                            "fontSize": "14px",
                                                                            "fontWeight": "400",
                                                                            "marginLeft": '25px'
                                                                        }}>{d.title}</span></Radio>
                                                        </li>
                                                    }
                                                    )}
                                                </Radio.Group>
                                            </ul>
                                        </div>
                                        )}
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export const UpdateAddress = Form.create()(withTranslation('common')(UpdateAddressForm));