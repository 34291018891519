import React, {Component} from 'react';
import {Button, DatePicker, Form, Input, Checkbox} from "antd";
import {withTranslation} from "react-i18next";
import DistributorClientFilter from "../../../../../filters/TableFilters/DistributorClientFilter";
import SalesRepresentativeFilter from "../../../../../filters/salesRepresentativeFilter";
import {dateFormat} from "../../../../../utils";

const {RangePicker} = DatePicker;

class WorkOrderDocumentFilter extends Component {
    state = {
        selected_status: [],
        statusOptions: ['draft', 'completed', 'in_queue', 'processing', 'cancel', 'partial_delivered']
    };

    handleFilterForm = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.state.selected_status.length > 0) {
                    values['status'] = JSON.stringify(this.state.selected_status)
                }
                if (values['creation_date']) {
                    if (this.props.distributorInvoice){
                            values['created_at__gte'] = values['creation_date'][0].format('YYYY-MM-DD');
                            values['created_at__lte'] = values['creation_date'][1].format('YYYY-MM-DD');
                    }else{
                        values['start_date'] = values['creation_date'][0].format('YYYY-MM-DD');
                        values['end_date'] = values['creation_date'][1].format('YYYY-MM-DD');
                    }

                    if(values['invoice_min_id']){
                        values['id__gte'] = values['invoice_min_id'];
                        values['id__lte'] = values['invoice_max_id']
                        delete values['invoice_min_id']
                        delete values['invoice_max_id']
                    }
                }
                delete values['creation_date']
                }
                this.props.onFilter(values)
        })
    };
    onReset = () => {
        this.props.form.resetFields();
        this.props.onFilter({})
        this.setState({ selected_status: [] })
    };

    onChangeValue = (selected_status) => {
        this.setState({ selected_status });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {t} = this.props;

        return (
            <div className="row mx-0 common-form-div">
                <div className="col-12 p-0">
                    <Form className="filter-work-order-form">
                        <div className="row mx-0">
                            <div className="col-12 col-sm-12 col-md-6">

                                <Form.Item label="Nom du distributeur :">
                                    {getFieldDecorator('client', {})(<DistributorClientFilter/>)}
                                </Form.Item>

                                <Form.Item label="Représentant :">
                                    {getFieldDecorator('sales_rep', {})(<SalesRepresentativeFilter labelInValue={true}/>)}
                                </Form.Item>

                                {this.props.invoice || this.props.distributorInvoice ? 
                                    <Form.Item label="Numéro du facture :">
                                        <React.Fragment>
                                            <div
                                                className="row mx-0 align-items-center position-relative justify-content-between">
                                                {getFieldDecorator('invoice_min_id', {})(<Input placeholder={"Entrer"}/>)}
                                                {getFieldDecorator('invoice_max_id', {})(<Input placeholder={"Entrer"}/>)}
                                                <span className="dash-connect-each-tag position-absolute">-</span>
                                            </div>
                                        </React.Fragment>
                                    </Form.Item>
                                    : <Form.Item label="Numéro du bon de livraison :">
                                        <React.Fragment>
                                            <div
                                                className="row mx-0 align-items-center position-relative justify-content-between">
                                                {getFieldDecorator('min_id', {})(<Input placeholder={"Entrer"}/>)}
                                                {getFieldDecorator('max_id', {})(<Input placeholder={"Entrer"}/>)}
                                                <span className="dash-connect-each-tag position-absolute">-</span>
                                            </div>
                                        </React.Fragment>
                                    </Form.Item>
                                }
                                <Form.Item label="Date de création :">
                                    {getFieldDecorator('date_de_creation', {})(
                                        <React.Fragment>
                                            <div className="row document-filter mx-0 ">
                                                {getFieldDecorator('creation_date', {})(<RangePicker
                                                    format={dateFormat}/>)}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Form.Item>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <Form.Item label={t('status')}>
                                    {getFieldDecorator('status', {})(
                                        <Checkbox.Group onChange={this.onChangeValue} className="drop-filter-radio">
                                            {this.state.statusOptions.map((d, index) => {
                                                return <div key={index}>
                                                    <Checkbox
                                                        className="position-relative"
                                                        value={d}>{t(d)}</Checkbox>
                                                </div>
                                            })}
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="col-12">
                    <div className="row mx-0 flex-align-center justify-content-end footer-btn-filter">
                        <Button onClick={this.onReset} className="mr-3">Réinitialiser</Button>
                        <Button onClick={this.handleFilterForm} type="primary">
                            Recherche
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(Form.create({name: 'workOrderFilter'})(WorkOrderDocumentFilter));
